@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Genos&display=swap');
.containerMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);

}

  .divCallTA1{
    flex-grow: 1;
    /* background-image: url(../images/background.jpg); */
    /* background-position: center; */
    /* background-size: contain; */
    object-fit: cover;
    /* color: rgb(48, 3, 3); */
    height: 100vh;
    width: 100% ; 
    position: absolute;
    /* margin-bottom: .5rem; */
  }

  .fondodetodo{
    background: rgb(26, 44, 56);
    
  }
.aputips{
   font-family: "Genos", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 2.1rem;
  /* font-family: "Roboto", sans-serif;
  font-weight: 900;
   font-style: normal;
   font-size: 2.1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  color: white;
}
  .bono{
    font-family: "Genos", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
     font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 10vh;
    color: whitesmoke;
  }
  .cards1{
    padding: 2vh;
  }
  .cardflex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }




  
  .b-game-card {
    position: relative;
    z-index: 1;
    width: 20vh;
    padding : 12vh;
    margin: 2vh;
    /* perspective: 1000px; */
   
  }
  .b-game-card__cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
  }
  .b-game-card__cover::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%);
    transform: translateY(-20%);
    will-change: transform;
    transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
  }
  .b-game-card:hover .b-game-card__cover {
    transform: rotateX(7deg) translateY(-6px);
  }
  .b-game-card:hover .b-game-card__cover::after {
    transform: translateY(0%);
  }
  .b-game-card::before {
    display: block;
    content: "";
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 6px 12px 12px rgba(0, 0, 0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
  }
  .b-game-card:hover::before {
    opacity: 0.6;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
  }
  

  
  /* From Uiverse.io by gharsh11032000 */ 
.bottonancord{
  text-decoration: none !important;
  display: flex;
  justify-content: center;}
.animated-button {
  font-family: "Genos", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: greenyellow;
  box-shadow: 0 0 0 2px greenyellow;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: greenyellow;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: greenyellow;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}

.animated-button:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px greenyellow;
}

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}



.footerwhitte{
  margin-top: 2vh;
  font-family: "Genos", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
   font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 5vh;
  color: white;
  margin: 2vh;
}

.imgfotter{
text-align: center;

}
.dMHlHA{
  width: 25vh;
  height: 5vh;
}

/* APUTIPS */

.letras p {
  display: inline-flex;
  color: cadetblue;
  font-size: 25px;
  font-weight: bold;
  padding: 0 2px;
}

p:nth-child(2),
p:nth-child(12) {
  animation: gangorra 3s alternate-reverse;
}
p:nth-child(3),
p:nth-child(11) {
  animation: gangorra 2s alternate-reverse ;
}
p:nth-child(4),
p:nth-child(10) {
  animation: gangorra 4s alternate-reverse ;
}
p:nth-child(5),
p:nth-child(9) {
  animation: gangorra 3s alternate-reverse ;
}
p:nth-child(6) {
  animation: gangorra 3s alternate-reverse ;
}
p:nth-child(8) {
  animation: gangorra 4s alternate-reverse ;
}

@keyframes gangorra {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(50%);
  }
}
.containerall{
  height: 100vh;
  background: rgb(26, 44, 56);
}

.usaelcod{
  background-color: #60666d;
  background-position: 2vh;
  border-radius: 6vh;
  color: whitesmoke;
  text-align: center;
  margin: 2vh;
  /* padding: 0.5vh; */
  font-family: "Genos", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
}
.usaelcod > spam {
  color: #00ff00;
  font-size: 1.2rem;
}

@media screen and (max-width: 1106px){
  .fondodetodo{
    background: rgb(26, 44, 56);
    
  }

}


  @media screen and (max-width: 600px)
  {
    .b-game-card {
      position: relative;
      z-index: 1;
      padding : 7vh;
      width: 3vh;
      margin: 1vh;
      perspective: 1000px;
     
    }
  
  }
    .divCallTA1{
       flex-grow: 1;
      /* background-image: url(../images/background.jpg); */
      /* background-position: center; */
      /* background-size: contain; */
      /* object-fit: cover; */
       
      
     
    
    }
    @media screen and (max-width: 340px)
  {
    .b-game-card {
      /* position: relative; */
      /* z-index: 1; */
      padding : 20%;
      width: 30%;
      margin: 3%;
      /* perspective: 1000px; */
     
    }
  
  }
    .divCallTA1{
       flex-grow: 1;
      /* background-image: url(../images/background.jpg); */
      /* background-position: center; */
      /* background-size: contain; */
      /* object-fit: cover; */
       
      
     
    
    }
    .bottonancord{
      text-decoration: none !important;
      display: flex;
      justify-content: center;}
    .animated-button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1px;
      padding: 10px 25px;
      border: 5px solid;
      border-color: transparent;
      font-size: 16px;
      background-color: inherit;
      border-radius: 100px;
      font-weight: 600;
      color: greenyellow;
      box-shadow: 0 0 0 2px greenyellow;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button svg {
      position: absolute;
      width: 20px;
      fill: greenyellow;
      z-index: 9;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button .arr-1 {
      right: 16px;
    }
    
    .animated-button .arr-2 {
      left: -25%;
    }
    
    .animated-button .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: greenyellow;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button .text {
      position: relative;
      z-index: 1;
      transform: translateX(-12px);
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button:hover {
      box-shadow: 0 0 0 12px transparent;
      color: #212121;
      border-radius: 12px;
    }
    
    .animated-button:hover .arr-1 {
      right: -25%;
    }
    
    .animated-button:hover .arr-2 {
      left: 16px;
    }
    
    .animated-button:hover .text {
      transform: translateX(12px);
    }
    
    .animated-button:hover svg {
      fill: #212121;
    }
    
    .animated-button:active {
      scale: 0.95;
      box-shadow: 0 0 0 4px greenyellow;
    }
    
    .animated-button:hover .circle {
      width: 220px;
      height: 220px;
      opacity: 1;
    }
    .usaelcod{
      background-color: #60666d;
      background-position: 2vh;
      border-radius: 6vh;
      color: whitesmoke;
      text-align: center;
      margin: 2vh;
      /* padding: 0.5vh; */
      font-family: "Genos", sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;
      font-style: normal;
      font-size: 0.8rem;
    }
    .usaelcod > spam {
      color: #00ff00;
      font-size: 0.9rem;
    }
